<template>
<div class="main">
      <div class="panels">
        <h2 style="margin-bottom:10px;color:white">Maintainers</h2>
        <v-btn class="ma-2" @click="createblog = true" style="color:#263AA2" v-show="loggedin">Add</v-btn>
        <v-dialog v-model="createblog" max-width="50%" :fullscreen="$vuetify.breakpoint.smAndDown">
            <form @submit.prevent="add" class="blog">
            <validation-provider
                v-slot="{ errors }"
                name="Maintainer Name"
                rules="required"
            >
            <v-text-field
                v-model="maintainer"
                :error-messages="errors"
                label="Maintainer Name"
            ></v-text-field>
            </validation-provider>

            <validation-provider
                v-slot="{ errors }"
                name="Maintainer Image"
                rules="required"
            >
            <v-text-field
                v-model="image"
                :error-messages="errors"
                label="Maintainer Image(If none use github dp)"
            ></v-text-field>
            </validation-provider>

            <validation-provider
                v-slot="{ errors }"
                name="Realme 5 Pro"
            >
            <v-select
                v-model="rmx1971"
                :error-messages="errors"
                label="Realme 5 Pro"
                :items="status"
            ></v-select>
            </validation-provider>

            <validation-provider
                v-slot="{ errors }"
                name="Realme 5 Series"
            >
            <v-select
                v-model="r5x"
                :error-messages="errors"
                label="Realme 5 Series"
                :items="status"
            ></v-select>
            </validation-provider>

            <validation-provider
                v-slot="{ errors }"
                name="Roms Maintained"
                rules="required"
            >
            <v-text-field
                v-model="roms"
                :error-messages="errors"
                label="Roms Maintained"
            ></v-text-field>
            </validation-provider>

            <validation-provider
                v-slot="{ errors }"
                name="Github Link"
                rules="required"
            >
            <v-text-field
                v-model="github"
                :error-messages="errors"
                label="Github Link"
            ></v-text-field>
            </validation-provider>

            <validation-provider
                v-slot="{ errors }"
                name="Telegram Id"
            >
            <v-text-field
                v-model="telegram"
                :error-messages="errors"
                label="Telegram Id"
            ></v-text-field>
            </validation-provider>

            <label style="color:#636c72;margin-bottom:1%;">About the mainatiner(if he/she wanted to share any)</label>
            <vue-editor
                v-model="content"
                :error-messages="errors"
            ></vue-editor>
            <v-btn
                color="primary"
                class="mr-4"
                type="submit"
            >
            Submit
            </v-btn>
            <v-btn
                class="ma-2"
                @click="createblog = false"
            >
            Close
            </v-btn>
            </form>
        </v-dialog>
        <v-expansion-panels>
            <v-expansion-panel
            v-for="(item,i) in this.length"
            :key="i"
            style="background:#467cc1;color:white;"
            >
            <v-expansion-panel-header>
                <div>{{ data[i].maintainer }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="paneldata">
                <div class="col-md-4">
                    <img :src="data[i].image" class="img-fluid rounded" :alt="data[i].maintainer">
                </div>
                <div class="panel">
                    <p>Devices maintained : <label v-show="data[i].rmx1971">Realme 5 Pro(RMX1971)</label> <label v-show="data[i].r5x">Realme 5 Series(r5x)</label></p>
                    <p>Roms Maintained : {{ data[i].roms }}</p>
                    <p>Github :  <a :href="data[i].github" style="color:white">{{ data[i].maintainer }}</a></p>
                    <p v-show="data[i].telegram">Telegram Id : {{ data[i].telegram }}</p>
                    <p style="white-space: pre-line;" v-show="data[i].content">Info about maintainer : <br>{{ data[i].content }}</p>
            </div>
            </div>
                <v-btn
                    class="ma-2"
                    style="color:#263AA2"
                    @click="editmaintainer = true"
                    v-show="loggedin"
                >
                    Edit
                </v-btn>
                <v-dialog v-model="editmaintainer" max-width="50%" :fullscreen="$vuetify.breakpoint.smAndDown">
                    <form @submit.prevent="update" class="blog">
                    <validation-provider
                        v-slot="{ errors }"
                        name="Maintainer Name"
                        rules="required"
                    >
                    <v-text-field
                        :value="data[i].maintainer"
                        ref="maintainer"
                        :error-messages="errors"
                        label="Maintainer Name"
                        disabled
                    ></v-text-field>
                    </validation-provider>

                    <validation-provider
                        v-slot="{ errors }"
                        name="Maintainer Image"
                        rules="required"
                    >
                    <v-text-field
                        :value="data[i].image"
                        ref="image"
                        :error-messages="errors"
                        label="Maintainer Image(If none use github dp)"
                    ></v-text-field>
                    </validation-provider>

                    <validation-provider
                        v-slot="{ errors }"
                        name="Realme 5 Pro"
                    >
                    <v-select
                        :value="data[i].rmx1971"
                        ref="rmx1971"
                        :error-messages="errors"
                        label="Realme 5 Pro"
                        :items="status"
                    ></v-select>
                    </validation-provider>

                    <validation-provider
                        v-slot="{ errors }"
                        name="Realme 5 Series"
                    >
                    <v-select
                        :value="data[i].r5x"
                        ref="r5x"
                        :error-messages="errors"
                        label="Realme 5 Series"
                        :items="status"
                    ></v-select>
                    </validation-provider>

                    <validation-provider
                        v-slot="{ errors }"
                        name="Roms Maintained"
                        rules="required"
                    >
                    <v-text-field
                        :value="data[i].roms"
                        ref="roms"
                        :error-messages="errors"
                        label="Roms Maintained"
                    ></v-text-field>
                    </validation-provider>

                    <validation-provider
                        v-slot="{ errors }"
                        name="Github Link"
                        rules="required"
                    >
                    <v-text-field
                        :value="data[i].github"
                        ref="github"
                        :error-messages="errors"
                        label="Github Link"
                    ></v-text-field>
                    </validation-provider>

                    <validation-provider
                        v-slot="{ errors }"
                        name="Telegram Id"
                    >
                    <v-text-field
                        :value="data[i].telegram"
                        ref="telegram"
                        :error-messages="errors"
                        label="Telegram Id"
                    ></v-text-field>
                    </validation-provider>

                    <label style="color:#636c72;margin-bottom:1%;">About the mainatiner(if he/she wanted to share any)</label>
                    <vue-editor
                        :value="data[i].content"
                        ref="content"
                        :error-messages="errors"
                    ></vue-editor>
                    <v-btn
                        color="primary"
                        class="mr-4"
                        type="submit"
                    >
                    Submit
                    </v-btn>
                    <v-btn
                        class="ma-2"
                        @click="editmaintainer = false"
                    >
                    Close
                    </v-btn>
                    </form>
                </v-dialog>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
      </div>
    <v-alert outlined transition="slide-y-transition" type="success" text :value="updatedpost"> Maintainer Data Updated successfully. </v-alert>
    <v-alert outlined type="error" text :value="failedupdatepost"> Failed to Update Maintainer Data. </v-alert>
    <v-alert outlined transition="slide-y-transition" type="success" text :value="addedpost"> Maintainer Data Created successfully. </v-alert>
    <v-alert outlined type="error" text :value="failedaddpost"> Failed to Create Maintainer Data. </v-alert>
</div>
</template>

<script>
import { required, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { VueEditor } from "vue2-editor";
import axios from 'axios';

  setInteractionMode('eager')

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('min', {
    ...min,
    message: '{_field_} must have atlest {length} characters',
  })

  export default {
    name: 'Maintainers',
    components: {
      ValidationProvider,
      ValidationObserver,
      VueEditor,
    },
    data: () => ({
        length: '',
        editmaintainer: false,
        loggedin: false,
        createblog: false,
        updatedpost: false,
        failedupdatepost: false,
        addedpost: false,
        failedaddpost: false,
        status: ['Y', 'N'],
    }),

    mounted () {
        this.checkloggedin();
        this.getmaintainers();
    },

    filters: {
        strippedContent: function(string) {
            return string.replace(/<\/?[^>]+>/ig, "\n"); 
        }
    },

    methods: {
        checkloggedin() {
            let data = JSON.parse(sessionStorage.getItem("data"))
            if (data && data['maintainers']=='Y') {
              this.loggedin = true
            }
        },
        getmaintainers() {
            let config = {
                method : 'get',
                url : `https://kharame-devices.herokuapp.com/getMaintainers`,
            };
            axios(config)
            .then((response) => {
                response.data.message
                this.length = response.data.message.length
                this.data = response.data.message
            })
        },
        add() {
            let data = {
                maintainer : this.maintainer,
                image : this.image,
                rmx1971 : this.rmx1971,
                r5x: this.r5x,
                roms: this.roms,
                github: this.github,
                telegram : this.telegram,
                content: this.content,
            };
            let config = {
                method : 'post',
                url : `https://kharame-devices.herokuapp.com/addMaintainer`,
                headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                },
                data: data
            };
            axios(config)
            .then((response) => {
            if (response.data && response.data.status === "S") {
              this.addedpost = true;
              this.getmaintainers();
              this.createblog = false;
              setTimeout(() => this.addedpost = false, 5000);
            } else {
              this.failedaddpost = true;
              this.createblog = false
            }
            })
        },
        update() {
            let data = {
                maintainer : this.$refs.maintainer[0].value,
                image : this.$refs.image[0].lazyValue,
                rmx1971 : this.$refs.rmx1971[0].selectedItems[0],
                r5x: this.$refs.r5x[0].selectedItems[0],
                roms: this.$refs.roms[0].lazyValue,
                github: this.$refs.github[0].lazyValue,
                telegram : this.$refs.telegram[0].lazyValue,
                content: this.$refs.content[0]._data.quill.editor.delta.ops[0].insert,
            };
            let config = {
                method : 'post',
                url : `https://kharame-devices.herokuapp.com/updateMaintainer`,
                headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                },
                data: data
            };
            axios(config)
            .then((response) => {
            if (response.data && response.data.status === "S") {
              this.updatedpost = true;
              this.getmaintainers();
              this.editmaintainer = false;
              setTimeout(() => this.updatedpost = false, 5000);
            } else {
              this.failedupdatepost = true;
              this.updateblog = false
            }
            })
        },
    },
  }
</script>
<style scoped>
.main {
  width : 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
}
.blog {
  background: white;
  padding: 10%;
}
.panels {
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
}
.paneldata {
    display: flex;
}
.panel {
    margin-left: 2.5%;
}
@media (max-width: 550px) {
    .paneldata {
        display: block;
    }
    .panel {
        margin-top: 10%;
    }
}
</style>